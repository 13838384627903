import { ExamService } from './../../../services/exam.service';
import { Search } from '../../../../Models/searchModel';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IpService } from 'src/app/services/ip.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from "@angular/router";
import * as moment from 'moment';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  searchObj: Search = {
    protocolo: '',
    data: 3
  }
  datasFilter = [
    {
      value: 1,
      name: '1 mês'
    }, {
      value: 2,
      name: '3 meses'
    }, {
      value: 3,
      name: 'Neste Ano'
    }, {
      value: 11,
      name: '2024'
    },{
      value: 10,
      name: '2023'
    }, {
      value: 9,
      name: '2022'
    }, {
      value: 8,
      name: '2021'
    }, {
      value: 7,
      name: '2020'
    }, {
      value: 6,
      name: '2019'
    }, {
      value: 5,
      name: '2018'
    }, {
      value: 4,
      name: '2017'
    }
  ]

  nologo: boolean = false
  ipAddress: string = ''

  app_android = environment.APP_ANDROID
  app_ios = environment.APP_IOS

  max: string = moment().format("yyyy-MM-DD")

  isLoading: boolean = false

  constructor(private examService: ExamService, private router: Router, private IpService: IpService, private route: ActivatedRoute) {
    sessionStorage.clear()
    this.nologo = window.location.pathname.indexOf('nologo') !== -1;
  }

  ngOnInit(): void {
    this.IpService.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
    })

    this.route.queryParams.subscribe(params => {
      this.searchObj.protocolo = params.protocolo;
    })
  }

  search() {
    if (!this.searchObj.protocolo) {
      return this.examService.showMessage('Necessário protocolo!')
    }

    this.isLoading = true;
    this.examService.getExams(this.searchObj).subscribe(exams => {
      this.isLoading = false;

      if (!exams || exams.length === 0) {
        return this.examService.showMessage('Verifique se todas as informações estão corretas!')
      }

      sessionStorage.setItem('exams', JSON.stringify(exams))
      if (this.nologo)
        this.router.navigate(['/exames/nologo'])
      else
        this.router.navigate(['/exames'])
    }, err => {
      this.isLoading = false;
      return this.examService.showMessage('Ocorreu um erro na busca dos exames!')
    })
  }
}
